let detail = {};
if (localStorage.getItem("ship_rocket_user")) {
  detail = JSON.parse(localStorage.getItem("ship_rocket_user"));
}

const currentDomain = window.location.origin;
let typeValue = 5; // Default value

if (currentDomain === "https://app.shiprath.com") {
  typeValue = 1;
}
console.log("currentDomain == ", currentDomain);
let config = {
  SUPPORT: true,
  site_url: "https://app.shiprath.com",

  apiUrl: "https://backend.shiprath.in/v.1.0.1/web/query",

  // apiUrl: "http://localhost:3002/v.1.0.1/web/query",
  type: typeValue,
  headers: {
    "Access-Control-Allow-Origin": "*",
    "Content-Type": "application/json",
    key: "E09F1280ghjghjg606C3BF43D882F479032F03B2C4172B795F997E03FA356604CA06A2C7090DBD6380454C39FD57BFCC6A24C712795021FB9501DBA54719285AFBC5AE5",
    token: detail.token ? detail.token : "",
    secretkey: detail.secret_key ? detail.secret_key : "",
    customerid: detail.customer_id ? detail.customer_id : "",
  },

  // token: null,
  UserId: null,
  // setToken: function(token) {
  //     this.headers.Token = token;
  //   },
};
// console.log("config =========>>>>>>>" , config)

export default config;
